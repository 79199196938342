
import Vue from 'vue';
import Product from '@/components/Products/PackageProduct.vue';
import ProductSelectDialog from '@/components/Proposal/ProductSelectDialog.vue';
import { ConfiguredFlight, ConfiguredProduct } from '@/shared/types';
import clonedeep from 'lodash.clonedeep';
import {
  BaseProductModelContract,
  PackageProductModelContract,
  PackageServiceContract,
  ProductService,
  ValidationServiceContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { InputValidationRules } from 'vuetify';
import { mapMutations } from 'vuex';
import { MutationTypes as ProductMutationTypes } from '@/store/product/mutations';
import { Scalars } from '@/app/graphql';
export default Vue.extend({
  name: 'PackageSettingsContainer',

  inject: ['showSnackbar'],

  useInjectable: [Services.Product, Models.PackageProduct, Models.BaseProduct, Services.Package],

  components: {
    Product,
    ProductSelectDialog,
  },

  props: {
    product: {
      type: Object || null,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    isFormValid: boolean;
    enableSelectProduct: boolean;
    localBudget: number | null;
    budgetIsFocused: boolean;
    canBeCanceled: boolean;
    defaultProduct: object;
    recalculationLoading: boolean;
    saveLoading: boolean;
    packageLoading: boolean;
  } => ({
    isFormValid: false,
    enableSelectProduct: false,
    localBudget: 0,
    budgetIsFocused: false,
    canBeCanceled: false,
    defaultProduct: {},
    recalculationLoading: false,
    saveLoading: false,
    packageLoading: false,
  }),

  async mounted(): Promise<void> {
    this.setPackage({});

    if (this.product && this.product.id) {
      this.packageLoading = true;
      await this.fetchPackageById(this.product.id);
      this.$refs.PackageSettingsForm.resetValidation();
      this.packageLoading = false;
    }

    this.$store.dispatch('product/fetchProductConfigs', {
      agencyId: this.$store.state.activeAgency.PropertyId,
      force: true,
    });
  },

  destroyed(): void {
    this.setPackageBudget(null);
    this.updatePackageProducts([]);
  },

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.$store.dispatch('product/setPackageProduct', null);
          this.$refs.PackageSettingsForm.resetValidation();
          this.$emit('close-dialog');
        }
      },
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state['product'].newPackage?.products || [];
    },
    canAddProduct(): boolean {
      return (
        !this.$store.state['product'].newPackage?.products?.length ||
        this.$store.state['product'].newPackage.products.every(product => product.hasOwnProperty('name'))
      );
    },
    budgetMinimum(): number {
      return Math.max(
        (this[Models.PackageProduct] as PackageProductModelContract).getMinSolutionBudget(this.selectedProducts),
        1000,
      );
    },
    budgetMaximum(): number {
      return 200000;
    },
    prettyBudget(): string {
      return this.$options.filters.formatPrice(this.budget);
    },
    budget: {
      get(): number {
        const budget = this.$store.state['product'].newPackage?.budget;
        try {
          if (typeof budget === 'string') {
            return parseInt(budget, 10);
          } else if (typeof budget === 'number') {
            return budget;
          }
          return 0;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          return 0;
        }
      },
      set(val: number): void {
        this.proposalDataUpdated = true;
        const newBudget = Math.min(Math.max(val, this.budgetMinimum), this.budgetMaximum);

        if (val < this.budgetMinimum) {
          this.showSnackbar(
            `Proposal Budget must be at least ${this.$options.filters.formatPrice(this.budgetMinimum)}`,
            'warning',
          );
        } else if (val > this.budgetMaximum) {
          this.showSnackbar(
            `Budget must not exceed ${this.$options.filters.formatPrice(this.budgetMaximum)}`,
            'warning',
          );
        }

        if (val) {
          this.setPackageBudget(newBudget);
          this.recalculateBudgets();
        }
      },
    },
    budgetRules(): InputValidationRules {
      return [
        () =>
          this.budget >= this.budgetMinimum ||
          `Budget must be at least ${this.$options.filters.formatPrice(this.budgetMinimum)}`,
        () =>
          this.budget <= this.budgetMaximum ||
          `Budget must not exceed ${this.$options.filters.formatPrice(this.budgetMaximum)}`,
      ];
    },
    currentPackage() {
      return this.$store.state['product'].newPackage;
    },
    description() {
      return this.currentPackage?.description || '';
    },
    budgetIsDisabled(): boolean {
      return (
        (this[Models.PackageProduct] as PackageProductModelContract).allProductsLocked(this.selectedProducts) &&
        !this.hasUnallocatedBudget
      );
    },
    hasUnallocatedBudget(): boolean {
      const selectedProducts = this.$store.state.product.newPackage?.products || [];
      return (
        !!selectedProducts.length &&
        selectedProducts.some(p => p?.name && p?.id) &&
        Boolean(
          (this[Models.PackageProduct] as PackageProductModelContract).getUnallocatedBudget(
            this.selectedProducts,
            this.budget,
          ),
        )
      );
    },
    isLoading() {
      return (
        this.$store.state.product.productLoading || this.recalculationLoading || this.saveLoading || this.packageLoading
      );
    },
  },

  methods: {
    ...mapMutations('product', {
      setPackage: ProductMutationTypes.SET_PACKAGE_PRODUCT,
      setXML: ProductMutationTypes.SET_XML,
      setNoXML: ProductMutationTypes.SET_NO_XML,
      setProducts: ProductMutationTypes.SET_PACKAGE_PRODUCTS,
      setProduct: ProductMutationTypes.UPDATE_PRODUCT_IN_PACKAGE,
      setProductWasChanged: ProductMutationTypes.UPDATE_PACKAGE_WAS_CHANGED,
      updateName: ProductMutationTypes.SET_PACKAGE_NAME,
      updateDescription: ProductMutationTypes.SET_PACKAGE_DESCRIPTION,
      setPackageBudget: ProductMutationTypes.SET_PACKAGE_BUDGET,
      togglePackageProductLocked: ProductMutationTypes.TOGGLE_PACKAGE_PRODUCT_LOCKED,
      updatePackageProductBudget: ProductMutationTypes.UPDATE_PACKAGE_PRODUCT_BUDGET,
      setFlightsToProduct: ProductMutationTypes.SET_PACKAGE_PRODUCT_FLIGHTS,
    }),
    closeDialog() {
      this.openDialog = false;
    },
    cancelConfig(updatedProduct) {
      const currentProducts = clonedeep(this.selectedProducts);
      const updatedIndex = currentProducts.findIndex(product => product.id === updatedProduct.id);
      if (updatedIndex !== -1) {
        currentProducts[updatedIndex] = { ...updatedProduct };
      }
      this.updatePackageProducts(currentProducts);
    },
    async saveAction() {
      this.saveLoading = true;
      const result = await this.savePackageSettings({ isSetToState: false });
      this.saveLoading = false;
      if (result) {
        this.closeConfirmation();
        this.$emit('refresh');
      }
    },
    async fetchPackageById(id: Scalars['UUID']) {
      const { isErr, unwrap } = await (this[Services.Package] as PackageServiceContract).getPackageById(id);
      if (isErr()) {
        this.showSnackbar("Can't get package at this time", 'error');
        return;
      }
      this.setPackage(unwrap());
    },
    async removeProduct(id) {
      const filtered = this.$store.state['product'].newPackage.products.filter(p => p.id !== id);
      await this.updatePackageProducts(filtered);
      this.recalculateBudgets();
    },
    async savePackageSettings({ updatedPackage }) {
      let savedPackage = updatedPackage;
      if (!updatedPackage) {
        savedPackage = this.currentPackage;
      }
      const agencyId = this.$store.state.activeAgency.PropertyId;

      if (this.product) {
        return this.updatePackage(savedPackage, agencyId);
      }
      return this.createPackage(savedPackage, agencyId);
    },
    createPackage(product, agencyId) {
      return this.$store.dispatch('product/createPackageSettings', {
        product,
        agencyId,
      });
    },
    updatePackage(product, agencyId) {
      return this.$store.dispatch('product/updatePackageSettings', {
        product,
        agencyId,
      });
    },
    onToggleSelectProducts() {
      this.enableSelectProduct = true;
    },
    closeConfirmation() {
      this.$emit('close-dialog');
    },
    addEmptyProduct(): void {
      const selected = this.selectedProducts || [];
      const maxIndex = Math.max(selected.length, Math.max(...selected.filter(p => p.index).map(p => p.index)));
      const newProduct = {
        order: Date.now().toString(),
        index: maxIndex + 1,
        budget: 0,
        minSpend: 0,
        minDays: 0,
        isLocked: false,
        isPlaceholder: true,
      };
      selected.push(newProduct);
      this.updatePackageProducts(selected);
    },
    setXml(productId, data) {
      this.setXML({ productId, ...data });
      this.updatePackageProductBudget({ _budget: data.totalSpend, _productId: productId });
      this.togglePackageProductLocked({ _status: true, _productId: productId });
      this.recalculateBudgets();
    },
    setNoXml(productId) {
      this.setNoXML(productId);
      this.togglePackageProductLocked({ _status: true, _productId: productId });
      this.recalculateBudgets();
    },
    deleteXml(productId) {
      this.setXML({ productId, broadcast: null, link: null });
      this.togglePackageProductLocked({ _status: false, _productId: productId });
      this.recalculateBudgets();
    },
    addFlight(product) {
      const newFlight = (this[Models.PackageProduct] as PackageProductModelContract).getNewFlight(product);
      const flights = [...product.flights, newFlight];
      const updatedProduct = this[Models.PackageProduct].getUpdatedProduct({
        ...product,
        flights: flights,
      });
      this.setProduct(updatedProduct);
      // this.setFlightsToProduct({ productId: product.id, flights: updatedProduct.flights });
    },
    removeFlight({ productId, flightId }) {
      this.$store.dispatch('product/removeFlight', { productId, flightId });
    },
    updateFlight({
      flight,
      productId,
    }: {
      flight: ConfiguredFlight;
      productId: string;
      sendUpdate?: boolean;
      recalculateProducts?: boolean;
    }) {
      const selectedProduct = this.$store.state['product'].newPackage.products;
      const product = selectedProduct.find(product => product.id === productId);

      if (!product) return;

      const flights = [...product.flights];
      const flightIndex = flights.findIndex(f => f.id === flight.id);
      if (flightIndex === -1) return flight;
      const updatedFlight = {
        ...flights[flightIndex],
        ...flight,
      };

      flights[flightIndex] = updatedFlight;

      const updatedProduct = this[Models.PackageProduct].getUpdatedProduct({
        ...product,
        flights: flights,
      });
      this.setProduct(updatedProduct);
      this.recalculateBudgets();
    },
    updateBudget(productId, budget) {
      let parsedBudget = budget;
      if (typeof budget === 'string') {
        parsedBudget = parseInt(budget, 10);
      }
      this.updatePackageProductBudget({ _budget: parsedBudget, _productId: productId });
      this.setProductWasChanged({ productId: productId, status: true });
      this.recalculateBudgets();
    },
    switchBudgetLock(id: string, budgetLock: boolean): void {
      this.togglePackageProductLocked({ _status: budgetLock, _productId: id });
      // to save budget after unlock
      if (!budgetLock) return this.setProductWasChanged({ productId: id, status: true });
      this.recalculateBudgets();
    },
    async recalculateBudgets() {
      const filteredProducts = this.selectedProducts.filter(product => !product.isPlaceholder);
      const changedStatusList = (
        this[Models.PackageProduct] as PackageProductModelContract
      ).getRecalculationPreparedProducts(filteredProducts);
      this.recalculationLoading = true;

      const { isErr, unwrap } = await (this[Services.Product] as ProductService).recalculateBudgets(
        changedStatusList,
        this.budget,
      );

      this.recalculationLoading = false;
      if (isErr()) {
        this.editBudget = false;
        return;
      }
      const { products, newBudget } = unwrap();
      const updatedList = products.map(product =>
        (this[Models.PackageProduct] as PackageProductModelContract).getUpdatedProduct(product),
      );
      this.setPackageBudget(newBudget);

      this.setProducts(updatedList);
    },
    addProduct({ id }) {
      const productSelection = this.$store.state.product.productConfigs.list.find(product => product.id === id);
      const maxIndex = (this[Models.PackageProduct] as PackageProductModelContract).getLastIndex(this.selectedProducts);
      const updatedProduct = (this[Models.PackageProduct] as PackageProductModelContract).getProductFromProductConfig(
        productSelection,
      );

      updatedProduct.index = maxIndex + 1;

      const selected = [...this.$store.state['product'].newPackage.products];
      const last = selected[selected.length - 1];
      if (last && last.isPlaceholder) {
        selected[selected.length - 1] = updatedProduct;
      } else {
        selected.push(updatedProduct);
      }
      this.updatePackageProducts(selected);
      this.recalculateBudgets();
    },
    newProductBudget(minSpend: number, existingBudget: number): number {
      const budgetObj = {
        min: minSpend,
        allocated: existingBudget,
      };
      return (this[Models.BaseProduct] as BaseProductModelContract).newProductBudget(
        budgetObj,
        this.$store.state['product'].newPackage,
      );
    },
    handleBudgetFocus(): void {
      if (this.readOnly) {
        if (this.$refs?.sideNavBudget) {
          this.$refs.sideNavBudget.blur();
        }
        return;
      }
      if (this.$refs?.sideNavBudget) {
        this.$refs.sideNavBudget.resetValidation();
      }
      this.localBudget = this.budget > 0 ? this.budget : null;
      this.budgetIsFocused = true;
    },
    handleBudgetBlur(): void {
      if (this.readOnly) return;
      this.budgetIsFocused = false;
      if (this.localBudget !== this.budget) {
        this.budget = this.localBudget;
      }
    },
    updatePackageProducts(products = []): void {
      const sorted = [...products].sort((a, b) => a.index - b.index);
      this.setProducts(sorted);
    },
  },
});
