
import Vue from 'vue';

export default Vue.extend({
  name: 'ProductBudget',

  props: {
    maxRecBudget: {
      type: Number,
    },
    minBudget: {
      type: Number,
    },
    recBudget: {
      type: Number,
    },
  },

  data: (): {
    recBudgetOffset: number;
    minBudgetOffset: number;
  } => ({
    recBudgetOffset: 0,
    minBudgetOffset: 0,
  }),

  computed: {
    budgetsAreEqual(): boolean {
      return this.recBudget === this.minBudget;
    },
  },

  methods: {
    formattedBudget(budget: number): string {
      if (!budget) {
        return '$0';
      }
      return this.$options.filters.formatPrice(budget);
    },
  },

  mounted(): void {
    this.minBudgetOffset = Math.max(Math.trunc((this.minBudget * 100) / this.maxRecBudget), 5);
    this.recBudgetOffset = Math.min(Math.trunc((this.recBudget * 100) / this.maxRecBudget), 95);
    if (this.recBudgetOffset - this.minBudgetOffset < 10) {
      this.minBudgetOffset = Math.max(Math.min(this.minBudgetOffset, this.recBudgetOffset - 5), 5);
      this.recBudgetOffset = Math.min(Math.max(this.recBudgetOffset, this.minBudgetOffset + 10), 95);
    }
  },
});
