
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { DataTableHeader } from 'vuetify';
import { Product } from '@/shared/types';

import { BaseProductModelContract, BeautifulRateType, ProductFulfillmentMethods } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { Modules } from '@/store';

import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import ProductBudget from '@/components/ProductBudget.vue';
import ProductActionsMenu from '@/components/Products/ProductActionsMenu.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import PackageSettingsContainer from '@/components/Products/PackageSettingsContainer.vue';
import AppBar from '@/widgets/products/products-app-bar.vue';
import { EditProduct } from '@/features';
import { ProductConfigCategory, RateType } from '@/app/graphql';
import AddProduct from '@/widgets/products/add-product.vue';

interface Data {
  expanded: [];
  search: string;
  headers: DataTableHeader[];
  isProductSaving: boolean;
  showAddProductDialog: boolean;
  newProduct: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  dialog: {
    package: boolean;
  };
}

export default Vue.extend({
  name: 'Products',

  components: {
    AddProduct,
    ProductInfoDialog,
    ProductBudget,
    ProductActionsMenu,
    WrapperWithTooltip,
    EditProduct,
    AppBar,
    PackageSettingsContainer,
  },

  useInjectable: [Services.UI, Services.Product, Models.Product, Models.BaseProduct],

  data(): Data {
    return {
      expanded: [],
      search: '',
      dialog: {
        package: false,
      },
      headers: [
        { text: '', width: '50px', align: 'start', value: 'logo', sortable: false, filterable: false },
        { text: 'Category', width: '170px', value: 'category' },
        { text: 'Product', value: 'name', width: '200px' },
        { text: 'Min Spend - Recommended Spend', width: '370px', value: 'budget', sortable: false, filterable: false },
        { text: 'Min Days', value: 'minDays', width: '100px' },
        { text: '', align: 'end', value: 'actions', width: '70px', sortable: false, filterable: false },
      ],
      isProductSaving: false,
      showAddProductDialog: false,
      newProduct: {
        name: '',
        minSpend: 0,
        recommendedBudget: 0,
        description: {
          short: '',
        },
        cpcMargin: 0,
        targetingOptions: [
          {
            index: Math.random().toString(16),
            targetingOption: '',
            rateType: 'CPC',
            rate: 0,
          },
        ],
        fulfillmentMethod: ProductFulfillmentMethods.Inhouse,
      },
    };
  },

  computed: {
    ...mapGetters(Modules.Auth, { isSuperUser: 'isAdmin' }),
    loading(): boolean {
      return this.$store.state.product.productsLoading || this.$store.state.client.loadingAgencies;
    },
    products(): Product[] {
      return this.$store.state.product.products;
    },
    maxRecBudget(): number {
      return Math.max.apply(
        Math,
        this.products.map(product => product.recommendedBudget || 0),
      );
    },
    rateTypes(): Array<{ text: string; value: string }> {
      return Object.values(RateType).map(key => ({ value: key, text: BeautifulRateType[key] }));
    },
    categories() {
      return Object.values(ProductConfigCategory).map(key => ({ value: key, text: this.getReadableCategory(key) }));
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    showAddProductButton(): boolean {
      return !this.loading && this.isAdmin;
    },
    currentUser(): string {
      const { user } = this.$store.state.auth;
      // ensure user.name is filename safe
      return user?.email?.split('@')?.[0]?.replace(/[^\w-]+/g, '_') || 'anonymous';
    },
    isGlobalProducts() {
      return this.$store.state.activeAgency.name === 'Global';
    },
  },

  methods: {
    ...mapMutations('enums', { setRateTypes: 'SET_RATE_TYPES' }),
    ...mapActions('product', ['saveProductSettings', 'getProducts', 'fetchProductConfigs']),
    productIcon(categoryName): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(categoryName).icon;
    },
    async onChangeAgency() {
      await this.getProducts();
    },
    isEditable() {
      return this.isAdmin || this.isAgencyAdmin;
    },
    getReadableCategory(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
  },
});
