
import Vue from 'vue';
import CsvUploadDialog from '@/components/CsvUploadDialog.vue';
import { KeywordSummary, SemKeyword } from '@/shared/types';
import { InputValidationRules } from 'vuetify';
import KeywordBody from '@/components/keywords/KeywordBody.vue';
import KeywordHeader from '@/components/keywords/KeywordHeader.vue';
import { Services } from '../../../injectables/tokens';
import { KeywordsServiceContract } from '../../../injectables';

export default Vue.extend({
  name: 'productSem',

  useInjectable: [Services.Keywords],

  components: { CsvUploadDialog, KeywordBody, KeywordHeader },

  data: (): {
    keywordIdeas: string[];
    customKeywords: string[];
    newKeywordIdea: string;
    useClientUrl: boolean;
    newCustomKeyword: string;
    openFileUpload: boolean;
    publicPath: string;
  } => ({
    newCustomKeyword: '',
    newKeywordIdea: '',
    keywordIdeas: [],
    customKeywords: [],
    useClientUrl: false,
    openFileUpload: false,
    publicPath: process.env.BASE_URL,
  }),

  props: {
    product: {
      type: Object,
    },
  },

  computed: {
    uploadHelperText(): string {
      return `CSV file smaller than 2MB. Max ${this.entryLimit} keywords allowed`;
    },
    entryLimit(): number {
      return !this.generatedKeywords?.length
        ? (this.keywordsService as KeywordsServiceContract).ideasLimit
        : (this.keywordsService as KeywordsServiceContract).keywordEntryLimit;
    },
    loadingKeywords(): boolean {
      return (
        this.$store.state['product'].loadingCustomKeywords ||
        this.$store.state['product'].loadingKeywordsSummary ||
        this.$store.state['product'].loadingKeywords
      );
    },
    clientHasUrl(): boolean {
      return (
        this.$store.getters['client/activeClient']?.hasOwnProperty('url') &&
        this.$store.getters['client/activeClient'].url.length > 0
      );
    },
    budget(): number {
      return this.product?.budget || 0;
    },
    generatedKeywords(): SemKeyword[] {
      return this.product?.keywords?.list || [];
    },
    keywordsSummary(): KeywordSummary {
      return this.product?.keywords?.summary || null;
    },
    customUnique(): InputValidationRules {
      return [
        v =>
          (!this.customKeywords.includes(v) && !this.generatedKeywords.some(kw => kw.keyword === v)) ||
          'This keyword has already been added',
      ];
    },
    ideaUnique(): InputValidationRules {
      return [v => !this.keywordIdeas.includes(v) || 'This idea is already selected'];
    },
  },

  methods: {
    updateNewKeywordIdea(value: string): void {
      this.newKeywordIdea = value;
    },
    updateNewCustomKeyword(value: string): void {
      this.newCustomKeyword = value;
    },
    resetKeywords(): void {
      this.$store
        .dispatch('product/resetKeywordsForPackage', this.product.id)
        .then(() => {
          this.customKeywords = [];
          this.keywordIdeas = [];
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    generateKeywords(): void {
      this.saveKeywordIdea();
      if (this.clientHasUrl && !this.keywordIdeas.length) {
        this.useClientUrl = true;
      }
      const reqObj = {
        productId: this.product.id,
        keywords: this.keywordIdeas,
        budget: this.budget,
      };
      this.$store
        .dispatch('product/getSemKeywordsForPackage', reqObj)
        .then(() => {
          this.useClientUrl = false;
          this.keywordIdeas = [];
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    fetchCustomKeywords(): void {
      this.saveCustomKeyword();
      if (!this.customKeywords?.length) {
        return;
      }
      const reqObj = {
        product: this.product,
        keywords: this.customKeywords,
        budget: this.budget,
      };
      this.$store
        .dispatch('product/getCustomKeywordsForPackage', reqObj)
        .then(response => {
          if (response?.keywords && Array.isArray(response.keywords) && response.keywords.length) {
            this.customKeywords = [];
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    toggleSelectedKeyword(keyword: string): void {
      const customKeywordIndex = this.customKeywords.findIndex(keywordObj => keywordObj.keyword === keyword);
      if (customKeywordIndex !== -1) {
        this.customKeywords[customKeywordIndex].isSelected = !this.customKeywords[customKeywordIndex].isSelected;
        return;
      }
      this.$store
        .dispatch('product/toggleSelectedKeywordForPackage', {
          keyword,
          productId: this.product.id,
          budget: this.budget,
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    removeCustomKeyword(keyword: string): void {
      const keywordIndex = this.customKeywords.indexOf(keyword);
      if (keywordIndex !== -1) {
        this.customKeywords.splice(keywordIndex, 1);
      }
    },
    removeIdea(idea: string): void {
      const ideaIndex = this.keywordIdeas.indexOf(idea);
      if (ideaIndex !== -1) {
        this.keywordIdeas.splice(ideaIndex, 1);
      }
    },
    saveCustomKeyword(): void {
      const trimmed = this.newCustomKeyword.trim();
      if (
        trimmed.length &&
        !this.customKeywords.includes(trimmed) &&
        !this.generatedKeywords.some(kw => kw.keyword === trimmed)
      ) {
        this.customKeywords.push(trimmed);
        this.newCustomKeyword = '';
      }
    },
    saveKeywordIdea(): void {
      const trimmed = this.newKeywordIdea.trim();
      if (trimmed.length && !this.keywordIdeas.includes(trimmed)) {
        this.keywordIdeas.push(trimmed);
        this.newKeywordIdea = '';
      }
    },
    applyUploadedFile(keywords: Array<string> = []): void {
      if (!keywords.length) return;
      let hasDuplicates = false;
      let hasMaxKeywords = false;

      if (this.generatedKeywords.length) {
        keywords.forEach(i => {
          if (this.customKeywords.length >= this.entryLimit) {
            hasMaxKeywords = true;
          } else if (this.customKeywords.indexOf(i) === -1) {
            this.customKeywords.push(i);
          } else {
            hasDuplicates = true;
          }
        });
      } else {
        keywords.forEach(i => {
          if (this.keywordIdeas.length >= this.entryLimit) {
            hasMaxKeywords = true;
          } else if (this.keywordIdeas.indexOf(i) === -1) {
            this.keywordIdeas.push(i);
          } else {
            hasDuplicates = true;
          }
        });
      }

      if (hasDuplicates || hasMaxKeywords) {
        const snackbarMessage = hasMaxKeywords
          ? `Only ${this.entryLimit} entries permitted at a time, some have been removed`
          : 'Some keywords already added';
        this.$store.dispatch('showSnackbar', { content: snackbarMessage, color: 'warning' });
      }
    },
  },
});
