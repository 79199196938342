
import Vue from 'vue';

import ProductsFilterMenu from '../Products/ProductsFilterMenu.vue';

import debounce from 'lodash.debounce';

// TODO: rewrite, use sparate component for each search.
// INFO: This is now only used in mp-front/src/widgets/products/products-app-bar.vue
export default Vue.extend({
  name: 'AppBarSearchComponent',

  components: {
    ProductsFilterMenu,
  },

  props: {
    searchBy: {
      type: String,
      required: true,
    },
    prominentAction: {
      type: Object,
    },
    routeName: {
      type: String,
      required: true,
    },
    searchComponent: {
      type: String,
      required: true,
    },
  },

  computed: {
    className(): string {
      return this.prominentAction || this.routeName === 'products' ? 'mr-4' : 'mr-7';
    },
    isInitFromState(): boolean {
      return this.routeName === 'proposals';
    },
  },

  watch: {
    searchComponent() {
      this.clearFilters();
    },
  },

  methods: {
    clearFilters(): void {
      this.$store.dispatch('updateSearchTerm', '');
      this.$store.dispatch('removeAllFilters');
    },
    debouncedSearchTermUpdate: debounce(function (str: string): void {
      this.$store.dispatch('updateSearchTerm', str);
    }, 500),

    applyProposalsFilters({ filters, searchKey }): void {
      this.$store.dispatch('updateFilters', filters);
      this.debouncedSearchTermUpdate(searchKey);
    },
  },
  mounted() {
    this.$store.dispatch('updateSearchTerm', '');
  },
  destroyed() {
    this.clearFilters();
  },
});
