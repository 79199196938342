import { render, staticRenderFns } from "./PackageSettingsContainer.vue?vue&type=template&id=ad7d1906&scoped=true"
import script from "./PackageSettingsContainer.vue?vue&type=script&lang=ts"
export * from "./PackageSettingsContainer.vue?vue&type=script&lang=ts"
import style0 from "./PackageSettingsContainer.vue?vue&type=style&index=0&id=ad7d1906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad7d1906",
  null
  
)

export default component.exports