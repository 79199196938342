
import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'AgencySelector',

  components: { C360Icon },

  props: {
    activeAgency: {
      type: Object,
      required: true,
    },
    allAgencies: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    sortedAgency() {
      return this.allAgencies.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
    inputStyles(): string {
      return `max-width: ${this.maxWidth}px`;
    },
  },
  methods: {
    changeAgency(agency) {
      if (agency === null) return;
      this.$emit('change-agency', agency);
    },
  },
});
