
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import PackageConfigurationModal from './PackageConfigurationModal.vue';
import { ConfiguredProduct, ProductConfig, UnsafeAny } from '@/shared/types';
import clonedeep from 'lodash.clonedeep';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProductStatusContainer from '@/components/ProductElement/ProductStatusContainer.vue';
import LockBudgetButton from '@/components/ProductElement/SpendConfiguration/LockBudgetButton.vue';
import ProductInfo from '@/components/ProductElement/ProductInfo.vue';

import SpendConfigurationSkeleton from '@/components/ProductElement/SpendConfiguration/SpendConfigurationSkeleton.vue';
import BudgetEditor from '@/components/ProductElement/SpendConfiguration/BudgetEditor.vue';
import { BaseProductModelContract, PackageProductModelContract, ProductService } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

export default Vue.extend({
  name: 'PackageProduct',

  useInjectable: [Models.PackageProduct, Models.BaseProduct],

  props: {
    product: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    isSkeleton: {
      type: Boolean,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    ProductInfoDialog,
    PackageConfigurationModal,
    WrapperWithTooltip,
    ProductStatusContainer,
    LockBudgetButton,
    ProductInfo,
    SpendConfigurationSkeleton,
    BudgetEditor,
  },
  data: (): {
    editBudget: boolean;
    selectProductName: boolean;
    productSearchText: string;
    enableFlightsConfig: boolean;
    localTextFieldBudget: number;
    localBudget: number;
    unallocatedBudget: number;
    isUsingSlider: boolean;
    productSelection: UnsafeAny;
    basicConfig: object;
  } => ({
    productSelection: { name: '' },
    isUsingSlider: false,
    unallocatedBudget: 0,
    localTextFieldBudget: 0,
    localBudget: 0,
    editBudget: false,
    enableFlightsConfig: false,
    productSearchText: '',
    selectProductName: false,
    basicConfig: {},
  }),
  watch: {
    budget(val: number): void {
      if (val !== this.localTextFieldBudget) {
        this.localTextFieldBudget = val;
      }
    },
    localTextFieldBudget(val: number): void {
      if (val !== this.localBudget) {
        this.localBudget = val;
      }
    },
    selectedProducts: {
      deep: true,
      handler(): void {
        this.calculateUnallocatedBudget();
      },
    },
    product(product): void {
      if (product.name) {
        this.selectProductName = false;
      }
    },
    editBudget(val: boolean) {
      if (val) {
        this.localTextFieldBudget = this.budget;
      }
    },
  },
  mounted(): void {
    if (!this.product?.name && !this.isSkeleton) {
      this.selectProductName = true;
    }
    if (this.budget) {
      this.localTextFieldBudget = this.budget;
      this.calculateUnallocatedBudget();
    }
    const autocompleteInput = this.$refs?.productAuto?.$refs?.input;
    if (autocompleteInput) autocompleteInput.addEventListener('focus', this.onFocus, true);
  },
  methods: {
    onAddProductClick() {
      if (this.disabled) return;
      this.$emit('add-empty-product');
    },
    cancelConfig(): void {
      this.enableFlightsConfig = false;
      this.$emit('cancel-config', this.basicConfig);
    },
    closeConfig() {
      this.enableFlightsConfig = false;
    },
    openConfig() {
      this.basicConfig = clonedeep(this.product);
      this.enableFlightsConfig = true;
    },
    productIsDisabled(minSpend: number): boolean {
      return !(this[Models.PackageProduct] as PackageProductModelContract).canAddNewProduct({
        minSpend,
        productBudget: this.budget,
        products: this.$store.state['product'].newPackage.products,
        solutionBudget: this.$store.state['product'].newPackage.budget,
      });
    },
    updateBudgetFromSlider(val: number) {
      this.isUsingSlider = false;
      this.updateBudget(val);
    },
    calculateUnallocatedBudget(): number {
      const proposalBudget = this.$store.state['product'].newPackage.budget;
      const allocatedBudget = this.$store.state['product'].newPackage.products
        .filter(product => product?.id && product.budget)
        .map(product => product.budget)
        .reduce((a, b) => a + b, 0);
      //  possibility that proposalBudget is 0, for now
      const unallocated = Math.max(0, proposalBudget - allocatedBudget);
      this.unallocatedBudget = unallocated;
      return unallocated;
    },
    newProductBudget(minSpend: number, existingBudget: number): number {
      const budgetObj = {
        min: minSpend,
        allocated: existingBudget,
      };
      return (this[Models.BaseProduct] as BaseProductModelContract).newProductBudget(
        budgetObj,
        this.$store.state['product'].newPackage,
      );
    },
    openSelectProduct(): void {
      this.$refs.productAuto.isMenuActive = false;
      this.$emit('toggle-select-products');
    },
    closeSelectProduct(): void {
      this.selectProductName = false;
      this.$refs.productAuto.isMenuActive = false;
    },
    onFocus(): void {
      this.$refs.productAuto.isMenuActive = true;
    },
    closeBudgetEditor(): void {
      this.editBudget = false;
      this.localTextFieldBudget = this.budget;
    },
    updateBudget(val: number) {
      this.$emit('change-budget', val);
    },
    manualBudgetUpdate(): void {
      if (this.localTextFieldBudget > this.productBudgetMax) {
        const newBudget = this.productBudgetMax;
        this.localTextFieldBudget = newBudget;
        this.updateBudget(newBudget);
        this.$store.dispatch('showSnackbar', {
          content: `Product budget cannot exceed ${newBudget}`,
          color: 'warning',
        });
      } else if (this.localTextFieldBudget < this.productBudgetMin) {
        const newBudget = this.productBudgetMin;
        this.updateBudget(newBudget);
        this.localTextFieldBudget = newBudget;
        this.$store.dispatch('showSnackbar', {
          content: `Product budget cannot be less than ${newBudget}`,
          color: 'warning',
        });
      } else {
        this.updateBudget(this.localTextFieldBudget);
      }
    },
    async updateBudgetCopy(val: number): Promise<void> {
      if (val === this.budget) {
        this.editBudget = false;
        return;
      }
      await this.$store.dispatch('product/updateProductBudget', { budget: val, productId: this.product.id });

      const { isErr, unwrap } = await (this[Services.Product] as ProductService).recalculateBudgets(
        this.selectedProducts,
        this.$store.state['product'].newPackage.budget,
      );
      if (isErr()) {
        this.editBudget = false;
        return;
      }
      this.setProducts(unwrap().products);
      this.$store.product.commit();
      this.calculateUnallocatedBudget();

      this.editBudget = false;
    },
    addProduct(): void {
      if (
        this.productSelection?.name &&
        this.productSelection?.id &&
        this.productSelection?.category &&
        !this.productIsDisabled(this.productSelection.minSpend)
      ) {
        return this.$emit('add-product', { product: this.product, id: this.productSelection?.id });
      }
      this.productSelection = { name: '' };
    },
    productIcon(categoryName?: string): string {
      let category = this.product.category;
      if (categoryName) {
        category = categoryName;
      }
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).icon;
    },
    productIconColor(categoryName?: string): string {
      let category = this.product.category;
      if (categoryName) {
        category = categoryName;
      }
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).color;
    },
    getReadableCategory(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
    productFilter(item, queryText): boolean {
      const textOne = item.name.toLowerCase();
      const textTwo = item.category.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    productIsConfigured(product) {
      return (this[Models.PackageProduct] as PackageProductModelContract).productIsConfigured(product);
    },
    xmlConfiguredProduct(product) {
      return (this[Models.BaseProduct] as BaseProductModelContract).xmlConfiguredProduct(product);
    },
  },
  computed: {
    XmlWithoutUpload(): boolean {
      const { noXML = false } = this.product || {};
      return noXML;
    },
    productClass(): string {
      return this.isNewProduct ? 'new-product' : this.invalid ? 'proposal-product warning-product' : 'proposal-product';
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.$store.state['product'].newPackage.products;
    },
    productHasBeenSelected(): boolean {
      return !!this.product?.name;
    },
    settingDotColor(): string {
      if (!this.product?.name) return 'grey lighten-2';
      if (this.productIsConfigured(this.product)) return 'success';
      return 'warning';
    },
    minAndMaxAreEqual(): boolean {
      return this.productBudgetMax === this.productBudgetMin;
    },
    productBudgetMin(): number {
      return this.product.minSpend;
    },
    productBudgetMax(): number {
      const maxBudget = (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
        this.product.id,
        this.$store.state.product.newPackage.budget,
        this.selectedProducts,
      );
      return maxBudget || 10000;
    },
    budget(): number {
      return this.product?.budget;
    },
    products(): { category: string; name: string }[] {
      const products = this.$store.state['product']?.productConfigs?.list || [];
      const selectedProducts = this.$store.state['product'].newPackage.products;
      return products.filter(
        product => !selectedProducts.some(p => p.productConfigId === product.id) && !product.isHidden,
      );
    },
    isNewProduct(): boolean {
      return !this.product?.name?.length;
    },
    budgetIsDisabled(): boolean {
      return (this[Models.PackageProduct] as PackageProductModelContract).budgetIsDisabled(this.product);
    },
    lockedButtonText(): string {
      if (this.xmlConfiguredProduct(this.product) && !this.XmlWithoutUpload) {
        return 'XML-based Budget';
      }

      if (this.budgetIsDisabled) {
        return 'Spend is locked';
      } else {
        return 'Spend unlocked';
      }
    },
  },
});
