
import ChildrenSelectModal from '@/entities/product/ChildrenSelectModal.vue';
import ProductSelectDialog from '@/components/Proposal/ProductSelectDialog.vue';
import { ProductServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { mapActions } from 'vuex';

export default {
  name: 'add-product',
  components: { ChildrenSelectModal, ProductSelectDialog },

  useInjectable: [Services.Product],

  data() {
    return {
      addProductMenu: false,
      globalProducts: {
        show: false,
        toChildren: {
          show: false,
          submenu: false,
        },
        items: [],
        selected: [],
      },
    };
  },

  computed: {
    isGlobalProducts() {
      return this.$store.state.activeAgency.name === 'Global';
    },
    isSuperUser(): boolean {
      return this.$store.getters['auth/isAdmin'];
    },
    isAgencySuperAdmin(): boolean {
      return this.$store.getters['auth/isAgencySuperAdmin'];
    },
    isParentAgency(): boolean {
      return this.$store.state.agency?.currentAgencyInfo?.parent === null || false;
    },
    canSaveToSubEntities(): boolean {
      return this.isAgencySuperAdmin && this.isParentAgency;
    },
    canAddGlobalProducts(): boolean {
      return this.isSuperUser;
    },
  },

  mounted() {
    if (this.canAddGlobalProducts && this.globalProducts.items.length === 0) this.getGlobalProducts();
  },

  methods: {
    ...mapActions('product', ['getProducts', 'setProductsLoading']),
    closeGlobalProduct() {
      this.globalProducts.show = false;
      this.globalProducts.selected = [];
      this.globalProducts.toChildren.show = false;
    },
    removeGlobalProduct(id) {
      this.globalProducts.selected = this.globalProducts.selected.filter(product => product.id !== id);
    },
    addGlobalProduct({ id }) {
      this.globalProducts.selected.push({ id });
    },
    async addProductsFromCompulse(agencyIds: string[] = []) {
      this.setProductsLoading(true); // The loading effect is through store state
      const selected = this.globalProducts.selected.map(product => product.id);
      this.closeGlobalProduct();

      const { isErr, unwrapErr } = await (this.productService as ProductServiceContract).addProductsFromCompulseTo(
        this.$store.state.agency?.currentAgencyInfo?.id,
        selected,
        agencyIds,
      );

      if (isErr()) {
        this.setProductsLoading(false);
        throw new Error(unwrapErr().message);
      }

      this.setProductsLoading(false);
      await this.getProducts();
    },
    async getGlobalProducts() {
      const { isErr, unwrap, unwrapErr } = await (this.productService as ProductServiceContract).getGlobalProducts();
      if (isErr()) {
        throw new Error(unwrapErr().message);
      }
      const data = unwrap();
      const { products = [], CanHavePackages = false } = data;

      this.globalProducts.items = products;
    },
  },
};
