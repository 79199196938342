
import Vue from 'vue';
import debounce from 'lodash.debounce';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'productsFiltersMenu',

  components: { C360Icon },

  data: (): {
    searchKey: string;
  } => ({
    searchKey: '',
  }),
  watch: {
    searchKey(value: string): void {
      this.debouncedSearchTermUpdate(value);
    },
  },
  methods: {
    debouncedSearchTermUpdate: debounce(function (str: string): void {
      this.$store.dispatch('updateSearchTerm', str);
    }, 500),
    clearDateRange(dateRange): void {
      dateRange.from = '';
      dateRange.to = '';
    },
    clearSearch(): void {
      this.searchKey = '';
      this.$store.dispatch('updateSearchTerm', '');
    },
  },
  destroyed() {
    this.$store.dispatch('updateSearchTerm', '');
  },
});
