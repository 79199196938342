
import { mapGetters } from 'vuex';
import Vue from 'vue';
import ProductOptionContainer from '@/components/Proposal/ProductOptionContainer.vue';
import SemKeyword from './Options/SemKeyword.vue';
import FlightsWrapper from '@/components/Proposal/Options/FlightsWrapper.vue';
import XmlProductOption from './Options/XMLProductOption.vue';
import { BaseProductModelContract, PackageProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

export default Vue.extend({
  components: {
    ProductOptionContainer,
    SemKeyword,
    FlightsWrapper,
    XmlProductOption,
  },

  useInjectable: [Models.PackageProduct, Models.BaseProduct],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close-config');
    },
    cancelConfig() {
      this.$emit('cancel-config');
    },
    productIcon(categoryName?: string): string {
      let category = this.product.category;
      if (categoryName) {
        category = categoryName;
      }
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).icon;
    },
    getReadableCategory(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
  },

  computed: {
    ...mapGetters({
      proposalBudgetMax: 'newProposal/proposalBudgetMax',
    }),
    productBudgetMax(): number {
      return (this[Models.BaseProduct] as BaseProductModelContract).productBudgetMax(
        this.product.id,
        this.$store.state.newProposal.newProposal.budget,
        this.$store.state.newProposal.newProposal.products,
      );
    },
    isXMLProduct(): boolean {
      return (this[Models.PackageProduct] as PackageProductModelContract).isXMLProduct(this.product);
    },
    isPaidSearch(): boolean {
      return (this[Models.PackageProduct] as PackageProductModelContract).isPaidSearchProduct(this.product);
    },
    documentLink() {
      return this.product?.broadcastInfo?.link;
    },
    BroadcastInfoList() {
      return this.product?.broadcastInfo?.broadcast;
    },
  },
});
