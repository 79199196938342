
import Vue from 'vue';
import XmlUploader from '@/components/Basic/Uploader/XmlUploader.vue';
import ConfiguredWithoutXml from '@/entities/product/ConfiguredWithoutXml.vue';
import { Services } from '@/injectables/tokens';
import { UploadServiceContract, BroadcastParserServiceContract } from '@/injectables';
import OldXmlTable from '../../Products/Options/OldXmlTable.vue';
import NewXmlTable from '../../Products/Options/NewXmlTable.vue';
import { ProductXmlFormat } from '@/app/graphql';

export default Vue.extend({
  // This is used in PACKAGES, it is identical to the other XMLProductOption.vue file except for
  // the name and this comment
  name: 'XMLProductOptionPackage',
  inject: ['showSnackbar'],

  useInjectable: [Services.Upload, Services.BroadcastParser],

  components: { XmlUploader, ConfiguredWithoutXml, OldXmlTable, NewXmlTable },
  data: (): {
    noDocumentLoading: boolean;
    loading: false;
  } => ({
    noDocumentLoading: false,
    loading: false,
  }),

  props: {
    closeDialog: {
      type: Function,
    },
    documentLink: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async continueWithoutUpload(): Promise<void> {
      this.$emit('set-no-xml');
    },
    async uploadXml($event: { file: File; xmlType: ProductXmlFormat }) {
      const file = $event.file;
      const xmlType = $event.xmlType;

      this.loading = true;
      const {
        unwrap: unwrapLink,
        isErr: isErrLink,
        unwrapErr: unwrapErrLink,
      } = await (this[Services.Upload] as UploadServiceContract).uploadXML(file);
      if (isErrLink()) {
        this.loading = false;
        this.showSnackbar(unwrapErrLink().message, 'error');
        return;
      }

      const link = unwrapLink();

      const { unwrap, isErr, unwrapErr } = await (
        this[Services.BroadcastParser] as BroadcastParserServiceContract
      ).parseXmlBroadcastFile(link, xmlType);

      this.loading = false;

      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }
      const programs = unwrap();
      const totalSpend = programs.reduce((acc, p) => acc + p.spend, 0);
      this.$emit('upload-xml', { broadcast: unwrap(), link, totalSpend });
    },
    async removeXml() {
      this.$emit('delete-xml');
    },
  },
  computed: {
    uploadDisabled(): boolean {
      const { noXmlFlag = false } = this.product || {};
      return noXmlFlag;
    },
    inProgress() {
      return this.$store.state['product'].isLoadXML;
    },
    programsCount(): number {
      const s = new Set((this.newBroadcastInfo || []).map(p => p.programName));
      return s.size;
    },
    spotsCount(): number {
      return (this.newBroadcastInfo || []).reduce((acc, p) => acc + Number.parseInt(p.spots), 0);
    },
    totalSpend(): number {
      return (this.newBroadcastInfo || []).reduce((acc, p) => acc + Number.parseFloat(p.spend), 0);
    },
    oldProgramsCount(): number {
      const s = new Set((this.oldBroadcastInfo || []).map(p => p.AvailName));
      return s.size;
    },
    oldSpotsCount(): number {
      return (this.oldBroadcastInfo || []).reduce((acc, p) => acc + Number.parseInt(p.SumSpots), 0);
    },
    oldTotalSpend(): number {
      return (this.oldBroadcastInfo || []).reduce((acc, p) => acc + Number.parseFloat(p.SumSpend), 0);
    },
    oldBroadcastInfo() {
      return this.product.broadcastInfo.broadcastOld || null;
    },
    newBroadcastInfo() {
      return this.product.broadcastInfo.broadcast || null;
    },
  },
});
