
import Vue, { PropType } from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { PitchAgency, AdProductUser } from '@/shared/types';

import { routes } from '@/entities/user';

import BaseAppBar from '@/entities/app/base-app-bar.vue';
import AgencySelector from '@/widgets/common/agency-selector.vue';
import SearchComponent from '@/components/AppBar/SearchComponent.vue';
import { C360Icon } from '@c360/ui';
import { Modules } from '@/store';

export default Vue.extend({
  name: 'ProductsAppBarWidget',

  inject: ['toggleDrawer'],

  components: {
    BaseAppBar,
    AgencySelector,
    SearchComponent,
    C360Icon,
  },

  props: {
    user: {
      type: Object as PropType<AdProductUser>,
      required: true,
    },
    withAgencySelector: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['activeAgency']),

    ...mapState('product', {
      hasPackages: 'agencyCanHavePackages',
    }),
    ...mapGetters(Modules.Auth, ['isAgencyAdmin', 'isAdmin']),
    userAgency() {
      return this.user.Agency;
    },
    allAgencies() {
      return [
        { PropertyId: 'Global', name: 'Global' },
        { PropertyId: this.userAgency, name: 'Agency' },
      ];
    },
    routes() {
      return routes;
    },
  },

  methods: {
    ...mapActions('client', ['getAgencies']),
    ...mapActions(['changeAgency']),
    async initAgencies() {
      return this.onChangeAgency({
        PropertyId: this.user?.Agency,
        name: this.user?.Agency,
      });
    },
    onChangeAgency(agency: PitchAgency) {
      this.changeAgency(agency);
      this.$emit('change-agency', agency);
    },
  },

  mounted() {
    this.initAgencies();
  },
});
