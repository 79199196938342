
import Vue from 'vue';

import { Routes } from '@/router/routes';
import { mapMutations } from 'vuex';

import PackageSettingsContainer from '@/components/Products/PackageSettingsContainer.vue';

import { Services } from '@/injectables/tokens';
import { Modules } from '@/store';
import { MutationTypes as ProductMutations } from '@/store/product/mutations';
import { ProductConfigCategory } from '@/app/graphql';
import ChildrenSelectModal from '@/entities/product/ChildrenSelectModal.vue';

export default Vue.extend({
  name: 'ProductActionsMenu',

  components: { ChildrenSelectModal, PackageSettingsContainer },

  useInjectable: [Services.Product, Services.Package],

  inject: ['$confirm'],

  props: {
    product: {
      type: Object,
      required: true,
    },
    rateTypes: {
      type: Array,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isGlobalProducts: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    menuOpened: boolean;
    showProductSettings: boolean;
    showPackageSettings: boolean;
    childrenSelectDialogShow: boolean;
  } => ({
    menuOpened: false,
    showProductSettings: false,
    showPackageSettings: false,
    childrenSelectDialogShow: false,
  }),

  computed: {
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    isAgencySuperAdmin(): boolean {
      return this.$store.getters['auth/isAgencySuperAdmin'];
    },
    isParentAgency(): boolean {
      return this.$store.state.agency?.currentAgencyInfo?.parent === null || false;
    },
    selectedAgency(): string {
      const userAgency = this.$store.state.auth.user.Agency;
      const activeAgency = this.$store.state.activeAgency?.PropertyId;
      return activeAgency && activeAgency !== 'Global' ? activeAgency : userAgency;
    },
  },

  methods: {
    ...mapMutations(Modules.Product, { updateProductSettings: ProductMutations.UPDATE_PRODUCT_SETTINGS }),
    navigateToProductSlides(): void {
      const { id: productId } = this.product;

      if (this.product?.category === ProductConfigCategory.Package) {
        this.$router.push({
          name: Routes.ManagePackageSlides,
          params: { agency: this.selectedAgency, packageConfigId: this.product.id },
        });

        return;
      }

      const selectedSubAgency = this.$route.query.subAgency;

      this.$router.push({
        name: Routes.ManageProductSlides,
        params: { agency: this.selectedAgency, productId },
        query: { subAgency: selectedSubAgency },
      });
    },
    isPackage(category) {
      return ProductConfigCategory.Package === category;
    },
    async toggleProductStatus(): Promise<void> {
      if (this.isPackage(this.product.category)) {
        const { isErr, unwrapErr } = await this.packageService.toggleHidePackage(this.product.id);

        if (isErr()) {
          const { message } = unwrapErr();
          // eslint-disable-next-line no-console
          console.error('toggleHidePackage error', message);
          return;
        }
        this.$emit('refresh');
      } else {
        const { isErr, unwrapErr } = await this.productService.toggleHideProduct(this.product.id);

        if (isErr()) {
          const { message } = unwrapErr();
          // eslint-disable-next-line no-console
          console.error('toggleHideProduct error', message);
          return;
        }

        this.$emit('refresh');
      }
    },
    async deleteProduct(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Delete Product',
        body: 'Deleting a product is permanent. Do you wish to proceed?',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      });

      if (!confirmed) return;

      const { isErr, unwrapErr } = await this.productService.deleteProductConfig(
        this.product.id,
        this.$store.state.activeAgency.PropertyId,
      );

      if (isErr()) {
        const { message } = unwrapErr();
        // eslint-disable-next-line no-console
        console.error('toggleHidePackage error', message);
        return;
      }

      this.$emit('refresh');
    },
    async deleteProductCascadeConfirm(childrenIds: string[] = []): Promise<void> {
      const { isErr, unwrapErr } = await this.productService.deleteProductConfigWithChildren(
        this.product.id,
        this.$store.state.activeAgency.PropertyId,
        childrenIds,
      );

      if (isErr()) {
        const { message } = unwrapErr();
        // eslint-disable-next-line no-console
        console.error('toggleHidePackage error', message);
        return;
      }

      this.$emit('refresh');
    },
    closeEditProduct() {
      this.showProductSettings = false;
    },
  },
});
